define("js2-admin/pods/components/matter/subjects/tags-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hUVJ0mB6",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[7,\"div\"],[12,\"id\",[29,\"concat\",[\"tags-\",[25,[\"row\",\"id\"]]],null]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"row\",\"tags\"]]],null,{\"statements\":[[0,\"        \"],[5,\"sc-badge\",[[13,\"class\",\"font-weight-normal mt-1\"],[13,\"title\",[24,1,[\"name\"]]]],[[\"@color\"],[[29,\"if\",[[29,\"eq\",[[24,1,[\"public\"]],1],null],\"success\",\"info\"],null]]],{\"statements\":[[0,\"\\n            \"],[1,[24,1,[\"name\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "js2-admin/pods/components/matter/subjects/tags-cell/template.hbs"
    }
  });

  _exports.default = _default;
});